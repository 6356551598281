import {createSlice} from '@reduxjs/toolkit';
import 'dotenv';
import { Api } from '../httpServices';
const { REACT_APP_API_URL } = process.env

export const serviceSlice = createSlice({
    name: 'service',
    initialState:{
        service: null,
        services : [],
        servicesByCategory : [],
        errorApiData: '',
        errorMessage: '',
        successMsg: '',
        serviceById : {},
        serviceData : {},
        deleteSuccess : false,
        statusSuccess: false,
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        allServices : (state, action) => {
            state.services = action.payload;
        },
        allServicesByCategory: (state,action) => {
            state.servicesByCategory = action.payload;
        },
        createService : (state, action) => {
            state.successMsg = 'The service has been successfully created.';
        },
        createServiceFailed: (state, action) => {
            state.errorMessage = action.payload;
        },
        serviceEdit : (state, action) => {
            state.serviceById = action.payload;
        },
        serviceUpdate : (state, action) => {
            state.successMsg = "Successfully update service.";
        },
        serviceDetail : (state, action) => {
            state.serviceData = action.payload;
        },
        serviceDelete : (state) => {
            state.deleteSuccess = true;
        },
        statusService: (state) => {
            state.statusSuccess = true;
        },
        resetState : (state) => {
            state.successMsg = "";
            state.deleteSuccess = "";
            state.errorApiData = "";
            state.statusSuccess = false;
        }
    }
}); 

export const {  statusService,apiFailed,allServicesByCategory, allServices, createService, serviceEdit, serviceUpdate, serviceDetail, serviceDelete,resetState } = serviceSlice.actions

export default serviceSlice.reducer

export const getServices = () => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/get-all-services`);
        dispatch(allServices(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
} 

export const getServicesByCategory = (subcatId) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/get-services-by-category`,subcatId);
        dispatch(allServicesByCategory(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}


export const addService = (values) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/add-service`, values);
        dispatch(createService(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const editService = (id) => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/view-service/${id}`)
        dispatch(serviceEdit(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const updateService = (values) => async dispatch => {
    try {
        const res = await Api('put', `${REACT_APP_API_URL}/update-service`, values)
        dispatch(serviceUpdate())
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const detailService = (_id) => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/view-service/${_id}`);
        dispatch(serviceDetail(res.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const deleteService = (_id) => async dispatch => {
    try {
        const res = await Api('delete', `${REACT_APP_API_URL}/delete-service/${_id}`)
        dispatch(serviceDelete());
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}


export const statusChanageService = (statusData) => async dispatch => {
    try{
        const res = await Api('post',`${REACT_APP_API_URL}/status-service`,statusData);
        dispatch(statusService());
    }catch(e){
        if(e.response){
            dispatch(apiFailed(e.response.message));
        }else{
            dispatch(apiFailed(e.message));
        }
    }
}