import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

export const categorySlice = createSlice({
    name: 'categories',
    initialState: {
        categories : [],
        subcategories : [],
        profile: {},
        category: null,
        categorys : [],
        errorApiData: '',
        errorMessage: '',
        successMsg: '',
        createSuccessMsg: '',
        categoryById : {},
        categoryUpd : {},
        categoryData : {},
        deleteSuccess : false,
        statusSuccess : false
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        allCategories : (state, action) => {
            state.categories = action.payload;
        },
        allSubCategories : (state,action)=>{
            state.subcategories = action.payload;
        },
        createCaregory : (state, action) => {
            state.successMsg = action.payload;
        },
        categoryEdit : (state, action) => {
            state.categoryById = action.payload;
        },
        categoryUpdate : (state, action) => {
            state.successMsg = action.payload;
        },
        categoryDetail : (state, action) => {
            state.categoryData = action.payload;
        },
        categoryDelete : (state) => {
            state.deleteSuccess = true;
        },
        statusCategory: (state) => {
            state.statusSuccess = true;
        },
        resetState : (state) => {
            state.successMsg = "";
            state.deleteSuccess = false;
            state.errorApiData = "";
            state.statusSuccess = false;
            state.createSuccessMsg = "";
            state.categoryById = "";
        },
    },
})

// Action creators are generated for each case reducer function
export const {resetState, apiFailed,allSubCategories, allCategories, createCaregory, categoryEdit, categoryUpdate, categoryDetail, categoryDelete, statusCategory } = categorySlice.actions
export default categorySlice.reducer

export const getCategories = () => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/get-all-categories`)
        dispatch(allCategories(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const getSubCategories = (id) => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/get-all-sub-categories/${id}`)
        dispatch(allSubCategories(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const addCategory = (values) => async dispatch => {
    try{
        const res = await Api('post', `${REACT_APP_API_URL}/add-category/`,values)
        dispatch(createCaregory(res.data.message));
    }catch(e){
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const addSubCategory = (values) => async dispatch => {
    try{
        const res = await Api('post', `${REACT_APP_API_URL}/add-sub-category/`,values)
        dispatch(createCaregory(res.data.message));
    }catch(e){
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const editCategory = (slug) => async dispatch => {
    try{
        const res = await Api('get', `${REACT_APP_API_URL}/view-category/${slug}`)
        dispatch(categoryEdit(res.data.data))
    }catch(e){
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const editSubCategory = (slug) => async dispatch => {
    try{
        const res = await Api('get', `${REACT_APP_API_URL}/view-sub-category/${slug}`)
        dispatch(categoryEdit(res.data.data))
    }catch(e){
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const updateSubCategory = (values) => async dispatch => {
    try{
        const res = await Api('put', `${REACT_APP_API_URL}/update-sub-category`, values)
        dispatch(categoryUpdate(res.data.message))
    }catch(e){
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const updateCategory = (values) => async dispatch => {
    try{
        const res = await Api('put', `${REACT_APP_API_URL}/update-category`, values)
        dispatch(categoryUpdate(res.data.message))
    }catch(e){
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const detailCategory = (slug) => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/view-category/${slug}`);
        dispatch(categoryDetail(res.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const deleteCategory = (slug) => async dispatch => {
    try {
        const res = await Api('delete', `${REACT_APP_API_URL}/delete-category/${slug}`)
        dispatch(categoryDelete());
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const statusChanageCategory = (statusData) => async dispatch => {
    try{

        const res = await Api('post',`${REACT_APP_API_URL}/status-category`,statusData);
        dispatch(statusCategory());
    }catch(e){
        if(e.response){
            dispatch(apiFailed(e.response.data.message));
        }else{
            dispatch(apiFailed(e.message));
        }
    }
}