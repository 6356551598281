import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";


import "./scss/style.scss";
import './scss/custom.css';


const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Login = React.lazy(() => import('./views/pages/login/Login'));
class App extends Component {
    render() {
        return (
            <Router>
                <Suspense fallback={loading}>
                    <Switch>
                        <Route exact path="/" name="Home" render={props => <TheLayout {...props} />} />
                        <Route exact path="/login" name="Login" render={props => <Login {...props} />} />
                        <Route exact path="/profile/:id" name="Profile" render={props => <TheLayout {...props} />} />
                        {/* Users Routes */}
                        <Route exact path="/users" exact name="Customers" render={props => <TheLayout {...props} />} />
                        <Route exact path="/user/add" name="Customer Add" render={props => <TheLayout {...props} />} />
                        <Route exact path="/user/edit/:slug" name="Customer Edit" render={props => <TheLayout {...props} />} />
                        <Route exact path="/user/detail/:slug" name="Customer Details" render={props => <TheLayout {...props} />} />
                        
                        {/* Service Providers Routes */}
                        <Route exact path="/service-providers"  exact name="Service Providers" render={props => <TheLayout {...props} />} />
                        <Route exact path="/service-providers/add" exact name="Service Provider Add" render={props => <TheLayout {...props} />} />
                        <Route exact path="/service-providers/edit/:id" exact name="Service Provider Edit" render={props => <TheLayout {...props} />} />
                        <Route exact path="/service-providers/detail/:slug" exact name="Service Provider Details" render={props => <TheLayout {...props} />} />


                        {/* Testimonials Routes */}
                        <Route exact path="/testimonials"  exact name="Testimonials" render={props => <TheLayout {...props} />} />
                        <Route exact path="/testimonial/add" exact name="Testimonial Add" render={props => <TheLayout {...props} />} />
                        <Route exact path="/testimonial/edit/:id" exact name="Testimonial Edit" render={props => <TheLayout {...props} />} />
                        <Route exact path="/testimonial/detail/:id" exact name="Testimonial Details" render={props => <TheLayout {...props} />} />


                        {/* Banners Routes */}
                        <Route exact path="/banners"  exact name="Banners" render={props => <TheLayout {...props} />} />
                        <Route exact path="/banner/add" exact name="Banner Add" render={props => <TheLayout {...props} />} />
                        <Route exact path="/banner/edit/:id" exact name="Banner Edit" render={props => <TheLayout {...props} />} />
                        <Route exact path="/banner/detail/:id" exact name="Banner Details" render={props => <TheLayout {...props} />} />

                        {/* Notifications Routes */}
                        <Route exact path="/notifications"  exact name="Notifications" render={props => <TheLayout {...props} />} />
                        <Route exact path="/notification/add" exact name="Notification Add" render={props => <TheLayout {...props} />} />
                        <Route exact path="/notification/edit/:id" exact name="Notification Edit" render={props => <TheLayout {...props} />} />
                        <Route exact path="/notification/send/:id/:usertype" exact name="Notification Send" render={props => <TheLayout {...props} />} />

                        {/* Site Settings Routes */}
                        <Route exact path="/site-settings" exact name="Site Settings" render={props => <TheLayout {...props} />} />
                        <Route exact path="/site-setting/add" name="Site Setting Add" render={props => <TheLayout {...props} />} />
                        <Route exact path="/site-setting/edit/:slug" name="Site Setting Edit" render={props => <TheLayout {...props} />} />

                        {/* Category Routes */}
                        <Route exact path="/categories" name="Categoris" render={props => <TheLayout {...props} />} />
                        <Route exact path="/category/demo" name="Category Demo" render={props => <TheLayout {...props} />} />
                        <Route exact path="/category/add" name="Category Add" render={props => <TheLayout {...props}/>} />
                        <Route exact path="/category/edit/:slug" name="Category Edit" render={props => <TheLayout {...props}/>} />
                        <Route exact path="/category/detail/:slug" name="Category View" render={props => <TheLayout {...props}/>} />
                        {/* Sub Category Routes */}
                        <Route exact path="/category/sub-category/:id" naem="Sub Category List" render={props => <TheLayout {...props}/>} />
                        <Route exact path="/category/sub-category/add/:id" naem="Sub Category Add" render={props => <TheLayout {...props}/>} />
                        <Route exact path="/category/sub-category/edit/:id/:slug" naem="Sub Category Edit" render={props => <TheLayout {...props}/>} />
                        <Route exact path="/category/sub-category/detail/:id/:slug" naem="Sub Category Detail" render={props => <TheLayout {...props}/>} />

                        {/* Services Routes */}
                        <Route exact path="/services" name="Services" render={props => <TheLayout {...props} />} />
                        <Route exact path="/service/add" naem="Service Add" render={props => <TheLayout {...props}/>} />
                        <Route exact path="/service/edit/:slug" naem="Service Edit" render={props => <TheLayout {...props}/>} />
                        <Route exact path="/service/detail/:slug" naem="Service View" render={props => <TheLayout {...props}/>} />

                        {/* Email Templates Routes */}
                        <Route exact path="/templates"  exact name="Templates" render={props => <TheLayout {...props} />} />
                        <Route exact path="/template/add" exact name="Template Add" render={props => <TheLayout {...props} />} />
                        <Route exact path="/template/edit/:id" exact name="Template Edit" render={props => <TheLayout {...props} />} />
                        <Route exact path="/template/detail/:id" exact name="Template Details" render={props => <TheLayout {...props} />} />

                        {/* Booking Routes */}
                        <Route exact path="/bookings" name="Bookings" render={props => <TheLayout {...props} />} />
                        <Route exact path="/booking/view/:id" name="Booking View" render={props => <TheLayout {...props} />} />

                        {/* Cms Routes */}
                        <Route exact path="/cms-list" name="Cms List" render={props => <TheLayout {...props} />} />
                        <Route exact path="/cms/edit/:id" name="Cms Edit" render={props => <TheLayout {...props} />} />

                        {/* Celebrity Routes */}
                        <Route exact path="/celebrity" name="Celebrity" render={props => <TheLayout {...props} />} />

                        {/* Post Routes */}
                        <Route exact path="/posts" name="Posts" render={props => <TheLayout {...props} />} />

                        {/* FAQ Routes */}
                        <Route exact path="/faq" name="Faq" render={props => <TheLayout {...props} />} />
                        <Route exact path="/faq/add" name="Add Faq" render={props => <TheLayout {...props} />} />
                        <Route exact path="/faq/edit/:id" name="Edit faq" render={props => <TheLayout {...props} />} />
                    </Switch>
                </Suspense>
            </Router>
        );
    }
}

export default App;