import { createSlice } from "@reduxjs/toolkit";
import "dotenv";
import { Api } from "../httpServices";
const { REACT_APP_API_URL } = process.env

export const faqSlice = createSlice({
    name: "faqs",
    initialState: {
        faq: {},
        successMessage: "",
        errorMessage: "",
        faqs: [],
        faqId: {},
        deleteSuccess: {},
    },
    reducers: {
        allFaq : (state, action) => {
            state.faqs = action.payload
        },
        createFaq : (state) => {
            state.successMessage = "Faq added successfully.";
        },
        faqEdit : (state, action) => {
            state.faqId = action.payload;
        },
        faqUpdate : (state) => {
            state.successMessage = "Faq update successfully.";
        },
        faqDelete : (state, action) => {
            state.deleteSuccess = action.payload;
        }
    }
});

export const { allFaq, createFaq, faqEdit, faqUpdate, faqDelete} = faqSlice.actions

export default faqSlice.reducer

export const getFaq = () => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/faq`);
        dispatch(allFaq(res.data.data));
    } catch (error) {
        console.log("error", error.message);
    }
}

export const addFaq = (values) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/add-faq`, values)
        dispatch(createFaq())
    } catch (e) {
        console.log("error", e.message);
    }
}

export const editFaq = (id) => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/edit-faq/${id}`)
        dispatch(faqEdit(res.data.data))
    } catch (e) {
        console.log("error", e.message);
    }
}

export const updateFaq = (values) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/update-faq`, values)
        dispatch(faqUpdate())
    } catch (e) {
        console.log("errfgsdgsdg_", e.message);
    }
}

export const deleteFaq = (id) => async dispatch => {
    try {
        const res = await Api('delete', `${REACT_APP_API_URL}/delete-faq/${id}`)
        console.log("test", res.data);
        dispatch(faqDelete(res.data));
    } catch (err) {
        console.log("delete_err", err.message);
    }
}