import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

export const siteSettingsSlice = createSlice({
    name: 'site settings',
    initialState: {
        settings : [],
        errorApiData: '',
        errorMessage: '',
        successMsg: '',
        siteSettingById : {},
        deleteSuccess : false,
        statusSuccess: false,
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        allSiteSettings : (state, action) => {
            state.settings = action.payload;
        },
        createSiteSetting : (state, action) => {
            state.successMsg = 'The site setting has been successfully created.';
        },
        createServiceProviderFailed: (state, action) => {
            state.errorMessage = action.payload;
        },
        siteSettingEdit : (state, action) => {
            state.siteSettingById = action.payload;
        },
        siteSettingsUpdate : (state, action) => {
            state.successMsg = action.payload;
        },
        resetState : (state) => {
            state.successMsg = "";
            state.deleteSuccess = "";
            state.errorApiData = "";
            state.statusSuccess = false;
        }
    },
})

// Action creators are generated for each case reducer function
export const {  apiFailed, allSiteSettings, createSiteSetting, siteSettingEdit, siteSettingsUpdate,resetState } = siteSettingsSlice.actions

export default siteSettingsSlice.reducer


export const getSiteSettings = () => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/get-site-settings`)
        dispatch(allSiteSettings(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const addSiteSettings = (values) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/add-site-setting`, values);
        dispatch(createSiteSetting(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}


export const updateSiteSetting = (values) => async dispatch => {
    try {
        const res = await Api('put', `${REACT_APP_API_URL}/update-site-setting`, values)
        dispatch(siteSettingsUpdate(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}



