import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

export const serviceProviderSlice = createSlice({
    name: 'service providers',
    initialState: {
        serviceProvider: null,
        serviceProviders : [],
        errorApiData: '',
        errorMessage: '',
        successMsg: '',
        serviceProviderById : {},
        serviceProviderData : {},
        deleteSuccess : false,
        statusSuccess: false,
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        allServiceProviders : (state, action) => {
            state.serviceProviders = action.payload;
        },
        createServiceProvider : (state, action) => {
            state.successMsg = 'The service provider has been successfully created.';
        },
        createServiceProviderFailed: (state, action) => {
            state.errorMessage = action.payload;
        },
        serviceProviderEdit : (state, action) => {
            state.serviceProviderById = action.payload;
        },
        serviceProviderUpdate : (state, action) => {
            state.successMsg = "Successfully update service provider.";
        },
        serviceProviderDetail : (state, action) => {
            state.serviceProviderData = action.payload;
        },
        serviceProviderDelete : (state) => {
            state.deleteSuccess = true;
        },
        statusServiceProvider: (state) => {
            state.statusSuccess = true;
        },
        resetState : (state) => {
            state.successMsg = "";
            state.deleteSuccess = "";
            state.errorApiData = "";
            state.statusSuccess = false;
        }
    },
})

// Action creators are generated for each case reducer function
export const {  statusServiceProvider,apiFailed, allServiceProviders, createServiceProvider, serviceProviderEdit, serviceProviderUpdate, serviceProviderDetail, serviceProviderDelete,resetState } = serviceProviderSlice.actions

export default serviceProviderSlice.reducer


export const getServiceProviders = () => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/get-service-providers`)
        dispatch(allServiceProviders(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const addServiceProvider = (values) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/add-service-provider`, values);
        dispatch(createServiceProvider(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const editServiceProvider = (id) => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/view-service-provider/${id}`)
        dispatch(serviceProviderEdit(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const updateServiceProvider = (values) => async dispatch => {
    try {
        const res = await Api('put', `${REACT_APP_API_URL}/update-service-provider`, values)
        dispatch(serviceProviderUpdate())
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const detailServiceProvider = (slug) => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/view-service-provider/${slug}`);
        dispatch(serviceProviderDetail(res.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const deleteServiceProvider = (_id) => async dispatch => {
    try {
        const res = await Api('delete', `${REACT_APP_API_URL}/delete-service-provider/${_id}`)
        dispatch(serviceProviderDelete());
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}


export const statusChanageServiceProvider = (statusData) => async dispatch => {
    try{
        const res = await Api('post',`${REACT_APP_API_URL}/status-service-provider`,statusData);
        dispatch(statusServiceProvider());
    }catch(e){
        if(e.response){
            dispatch(apiFailed(e.response.message));
        }else{
            dispatch(apiFailed(e.message));
        }
    }
}