import {createSlice} from "@reduxjs/toolkit";
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env


export const notificationSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications : [],
        users : [],
        notification: null,
        errorApiData: '',
        errorMessage: '',
        successMsg: '',
        createSuccessMsg: '',
        notificationById : {},
        notificationUpd : {},
        notificationData : {},
        deleteSuccess : false,
        statusSuccess : false
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        allNotifications : (state, action) => {
            state.notifications = action.payload;
        },
        createNotification : (state, action) => {
            state.successMsg = action.payload;
        },
        notificationEdit : (state, action) => {
            state.notificationById = action.payload;
        },
        notificationUpdate : (state, action) => {
            state.successMsg = action.payload;
        },
        allUsers : (state, action) => {
            state.users = action.payload;
        },
        notificationSend : (state, action) => {
            console.log("send",action)
            state.successMsg = action.payload;
        },
        notificationDelete : (state) => {
            state.deleteSuccess = true;
        },
        resetState : (state) => {
            state.successMsg = "";
            state.deleteSuccess = false;
            state.errorApiData = "";
            state.statusSuccess = false;
            state.createSuccessMsg = "";
            state.notificationById = "";
        },
    },
})

// Action creators are generated for each case reducer function
export const {resetState,apiFailed,allNotifications, createNotification, notificationEdit, notificationUpdate, allUsers, notificationSend, notificationDelete } = notificationSlice.actions
export default notificationSlice.reducer

export const getNotifications = () => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/notifications`)
        dispatch(allNotifications(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}


export const viewNotification = (id) => async dispatch => {
    console.log('calling....',id);
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/notification/view/${id}`,)
        dispatch(notificationEdit(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}


export const addNotification = (value) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/notification/add`,value)
        dispatch(createNotification(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const updateNotification = (value) => async dispatch => {
    try {
        const res = await Api('put', `${REACT_APP_API_URL}/notification/update`,value)
        dispatch(createNotification(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const deleteNotification = (id) => async dispatch => {
    try {
        const res = await Api('delete', `${REACT_APP_API_URL}/notification/delete/${id}`)
        dispatch(notificationDelete(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const getUsers = (id,usertype) => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/users/list/${id}/${usertype}`)
        dispatch(allUsers(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const sendNotification = (id,value) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/notification/send/${id}`,value)
        dispatch(notificationSend(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}