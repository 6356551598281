import {createSlice} from "@reduxjs/toolkit";
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env


export const emailTemplateSlice = createSlice({
    name: 'email templates',
    initialState: {
        templates : [],
        template: null,
        errorApiData: '',
        errorMessage: '',
        successMsg: '',
        createSuccessMsg: '',
        templateById : {},
        templateUpd : {},
        templateData : {},
        deleteSuccess : false,
        statusSuccess : false
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        allTemplates : (state, action) => {
            state.templates = action.payload;
        },
        createTemplate : (state, action) => {
            state.successMsg = action.payload;
        },
        templateEdit : (state, action) => {
            state.templateById = action.payload;
        },
        templateUpdate : (state, action) => {
            state.successMsg = action.payload;
        },
        templateDetail : (state, action) => {
            state.templateData = action.payload;
        },
        templateDelete : (state) => {
            state.deleteSuccess = true;
        },
        resetState : (state) => {
            state.successMsg = "";
            state.deleteSuccess = false;
            state.errorApiData = "";
            state.statusSuccess = false;
            state.createSuccessMsg = "";
            state.templateById = "";
        },
    },
})

// Action creators are generated for each case reducer function
export const {resetState,apiFailed,allTemplates, createTemplate, templateEdit, templateUpdate, templateDetail, templateDelete } = emailTemplateSlice.actions
export default emailTemplateSlice.reducer

export const getTemplates = () => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/templates`)
        dispatch(allTemplates(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}


export const viewTemplate = (id) => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/template/view/${id}`,)
        dispatch(templateEdit(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}


export const addTemplate = (value) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/template/add`,value)
        dispatch(createTemplate(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const updateTemplate = (value) => async dispatch => {
    try {
        const res = await Api('put', `${REACT_APP_API_URL}/template/update`,value)
        dispatch(createTemplate(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const deleteTemplate = (id) => async dispatch => {
    try {
        const res = await Api('delete', `${REACT_APP_API_URL}/template/delete/${id}`)
        dispatch(templateDelete(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}