import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

export const bookingSlice = createSlice({
    name: 'bookings',
    initialState: {
        bookings : [],
        bookinglist : [],
        errorApiData: '',
        errorMessage: '',
        successMsg: '',
        bookingById : {},
        bookingUpd : {},
        bookingData : {},
        deleteSuccess : false,
        statusSuccess : false
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        allBookings : (state, action) => {
            state.bookings = action.payload;
        },
        BookingDetailData : (state, action) => {
            state.bookingData = action.payload;
        },
        bookingDelete : (state) => {
            state.deleteSuccess = true;
        },
        statusBooking: (state) => {
            state.statusSuccess = true;
        },
        resetState : (state) => {
            state.successMsg = "";
            state.deleteSuccess = false;
            state.errorApiData = "";
            state.statusSuccess = false;
            state.createSuccessMsg = "";
            state.bookingById = "";
        },
    },
})

// Action creators are generated for each case reducer function
export const {resetState, apiFailed,allBookings,BookingDetailData } = bookingSlice.actions
export default bookingSlice.reducer

export const getBookings = () => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/booking-list`)
        dispatch(allBookings(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const deleteBooking = () => dispatch => {

}


export const viewBooking = (id) => async dispatch => {
    try {
        //console.log('helllo');
        const res = await Api('get', `${REACT_APP_API_URL}/booking-view/${id}`)
        dispatch(BookingDetailData(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}