import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        totalCustomer: 0,
        totalService:0,
        totalBooking:0,
        totalServiceProvider: 0,
        totalCategory:0,
        errorApiData: '',
        sidebarShow: 'responsive'
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        apiSuccess: (state, action) => {
            state.totalCustomer = action.payload.customer;
            state.totalServiceProvider = action.payload.serviceProvider;
            state.totalCategory = action.payload.category;
            state.totalService = action.payload.service;
            state.totalBooking = action.payload.booking;
        },
        sideBarShow:(state,action) => {
            console.log('action.payload',action.payload)
            state.sidebarShow = action.payload;
        }
    },
});

export const { apiFailed,apiSuccess,sideBarShow } = dashboardSlice.actions

export default dashboardSlice.reducer

export const dashboardData = () => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/dashboard`);
        dispatch(apiSuccess(res.data.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const sidebarHideShow = (val) => async dispatch => {
    try{
        dispatch(sideBarShow(val));
    }catch(e){
        console.log('error',e)
    }
}