import {createSlice} from "@reduxjs/toolkit";
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env


export const bannerSlice = createSlice({
    name: 'banners',
    initialState: {
        banners : [],
        banner: null,
        errorApiData: '',
        errorMessage: '',
        successMsg: '',
        createSuccessMsg: '',
        bannerById : {},
        bannerUpd : {},
        bannerData : {},
        deleteSuccess : false,
        statusSuccess : false
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        allBanners : (state, action) => {
            state.banners = action.payload;
        },
        createBanner : (state, action) => {
            state.successMsg = action.payload;
        },
        bannerEdit : (state, action) => {
            state.bannerById = action.payload;
        },
        bannerUpdate : (state, action) => {
            state.successMsg = action.payload;
        },
        bannerDetail : (state, action) => {
            state.bannerData = action.payload;
        },
        bannerDelete : (state) => {
            state.deleteSuccess = true;
        },
        resetState : (state) => {
            state.successMsg = "";
            state.deleteSuccess = false;
            state.errorApiData = "";
            state.statusSuccess = false;
            state.createSuccessMsg = "";
            state.bannerById = "";
        },
    },
})

// Action creators are generated for each case reducer function
export const {resetState,apiFailed,allBanners, createBanner, bannerEdit, bannerUpdate, bannerDetail, bannerDelete } = bannerSlice.actions
export default bannerSlice.reducer

export const getBanners = () => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/banners`)
        dispatch(allBanners(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}


export const viewBanner = (id) => async dispatch => {
    console.log('calling....',id);
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/banner/view/${id}`,)
        dispatch(bannerEdit(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}


export const addBanner = (value) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/banner/add`,value)
        dispatch(createBanner(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const updateBanner = (value) => async dispatch => {
    try {
        const res = await Api('put', `${REACT_APP_API_URL}/banner/update`,value)
        dispatch(createBanner(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const deleteBanner = (id) => async dispatch => {
    try {
        const res = await Api('delete', `${REACT_APP_API_URL}/banner/delete/${id}`)
        dispatch(bannerDelete(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}