import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import 'dotenv'
import { USER_TOKEN } from '../config'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env
export const userSlice = createSlice({
    name: 'users',
    initialState: {
        profile: {},
        user: null,
        users : [],
        errorApiData: '',
        errorMessage: '',
        successMsg: '',
        createSuccessMsg: '',
        userById : {},
        userUpd : {},
        userData : {},
        deleteSuccess : false,
        statusSuccess : false,
        allCelebrity : []
    },
    reducers: {
        loginSuccess: (state, action) => {
            state.user = action.payload;
        },
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        logoutSuccess: (state, action) => {
            state.user = null;
        },
        logoutFailed: (state, action) => {
            state.errorMessage = action.payload;
        },
        adminProfile: (state, action) => {
            state.profile = action.payload;
        },
        updateProfile: (state, action) => {
            state.successMsg = action.payload.data.message;
        },
        allUsers : (state, action) => {
            state.users = action.payload;
        },
        createUser : (state, action) => {
            state.successMsg = action.payload;
        },
        createUserFailed: (state, action) => {
            state.errorMessage = action.payload;
        },
        userEdit : (state, action) => {
            state.userById = action.payload;
        },
        userUpdate : (state, action) => {
            state.successMsg = action.payload;
        },
        userDetail : (state, action) => {
            state.userData = action.payload;
        },
        userDelete : (state) => {
            state.deleteSuccess = true;
        },
        listCelebrity : (state, action) => {
            state.allCelebrity = action.payload;
        },
        resetState : (state) => {
            state.successMsg = "";
            state.deleteSuccess = false;
            state.errorApiData = "";
            state.statusSuccess = false;
            state.createSuccessMsg = "";
        },
        statusUser: (state) => {
            state.statusSuccess = true;
        }
    },
})

// Action creators are generated for each case reducer function
export const { statusUser, adminProfile, updateProfile, loginSuccess, logoutSuccess, apiFailed, logoutFailed, allUsers, createUser, userEdit, userUpdate, userDetail, userDelete, listCelebrity, resetState } = userSlice.actions

export default userSlice.reducer

export const login = (values) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/login`, values);
        dispatch(loginSuccess(res.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const logout = () => async dispatch => {
    try {
        dispatch(logoutSuccess());
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const getProfile = () => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/profile`);
        dispatch(adminProfile(res.data.data));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const profileUpdate = (values) => async dispatch => {
    try {
        const res = await Api('put', `${REACT_APP_API_URL}/profile-update`, values);
        if(res.status == 200){
          dispatch(adminProfile(res.data.data));
          dispatch(updateProfile(res));
        }else{
          dispatch(apiFailed(res.message))
        }
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const getUsers = () => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/get-all-customers`)
        dispatch(allUsers(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const addUser = (values) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/add-customer`, values)
        dispatch(createUser(res.data.message));
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const editUser = (id) => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/view-customer/${id}`)
        dispatch(userEdit(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const updateUser = (values) => async dispatch => {
    try {
        const res = await Api('put', `${REACT_APP_API_URL}/update-customer`, values)
        dispatch(userUpdate(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const detailUser = (slug) => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/view-customer/${slug}`);
        dispatch(userDetail(res.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const deleteUser = (slug) => async dispatch => {
    try {
        const res = await Api('delete', `${REACT_APP_API_URL}/delete-customer/${slug}`)
        dispatch(userDelete());
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}


export const statusChanageUser = (statusData) => async dispatch => {
    try{

        const res = await Api('post',`${REACT_APP_API_URL}/status-customer`,statusData);
        dispatch(statusUser());
    }catch(e){
        if(e.response){
            dispatch(apiFailed(e.response.data.message));
        }else{
            dispatch(apiFailed(e.message));
        }
    }
}