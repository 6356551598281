import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

export const postSlice = createSlice({
    name: 'posts',
    initialState: {
        posts : [],
        successMsg: "",
    },
    reducers: {
        allPost : (state, action) => {
            state.posts = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const { allPost } = postSlice.actions

export default postSlice.reducer

export const getPost = () => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/get-posts`);
        dispatch(allPost(res.data.data))
    } catch (e) {
        console.log("error", e.message);
    }
}