import { configureStore } from '@reduxjs/toolkit';
import userReducer from './redux-slices/userSlice';
import serviceProviderReducer from './redux-slices/serviceProviderSlice';
import dashboardReducer from './redux-slices/dashboardSlice';
import faqReducer from './redux-slices/faqSlice';
import postReducer from './redux-slices/postSlice';
import categoryReducer from './redux-slices/categorySlice';
import serviceReducer from './redux-slices/serviceSlice';
import bookingReducer from './redux-slices/bookingSlice';
import cmsReducer from './redux-slices/cmsSlice';
import testimonialReducer from './redux-slices/testimonialSlice';
import bannerReducer from './redux-slices/bannerSlice';
import notificationReducer from './redux-slices/notificationSlice';
import siteSettingsReducer from './redux-slices/siteSettingsSlice';
import emailTemplateReducer from './redux-slices/emailTemplateSlice';

export const store = configureStore({
    reducer: {
        userReducer: userReducer,
        faqReducer: faqReducer,
        postReducer: postReducer,
        serviceProviderReducer: serviceProviderReducer,
        dashboardReducer: dashboardReducer,
        categoryReducer: categoryReducer,
        serviceReducer: serviceReducer,
        bookingReducer: bookingReducer,
        cmsReducer: cmsReducer,
        testimonialReducer: testimonialReducer,
        bannerReducer:bannerReducer,
        notificationReducer:notificationReducer,
        siteSettingsReducer:siteSettingsReducer,
        emailTemplateReducer:emailTemplateReducer
    },
})