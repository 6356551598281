import {createSlice} from "@reduxjs/toolkit";
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env


export const testimonialSlice = createSlice({
    name: 'testimonials',
    initialState: {
        testimonials : [],
        testimonial: null,
        testimonials : [],
        errorApiData: '',
        errorMessage: '',
        successMsg: '',
        createSuccessMsg: '',
        testimonialById : {},
        testimonialUpd : {},
        testimonialData : {},
        deleteSuccess : false,
        statusSuccess : false
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        allTestimonials : (state, action) => {
            state.testimonials = action.payload;
        },
        createTestimonial : (state, action) => {
            state.successMsg = action.payload;
        },
        testimonialEdit : (state, action) => {
            state.testimonialById = action.payload;
        },
        testimonialUpdate : (state, action) => {
            state.successMsg = action.payload;
        },
        testimonialDetail : (state, action) => {
            state.testimonialData = action.payload;
        },
        testimonialDelete : (state) => {
            state.deleteSuccess = true;
        },
        resetState : (state) => {
            state.successMsg = "";
            state.deleteSuccess = false;
            state.errorApiData = "";
            state.statusSuccess = false;
            state.createSuccessMsg = "";
            state.testimonialById = "";
        },
    },
})

// Action creators are generated for each case reducer function
export const {resetState,apiFailed,allTestimonials, createTestimonial, testimonialEdit, testimonialUpdate, testimonialDetail, testimonialDelete } = testimonialSlice.actions
export default testimonialSlice.reducer

export const getTestimonials = () => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/testimonials`)
        dispatch(allTestimonials(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}


export const viewTestimonial = (id) => async dispatch => {
    console.log('calling....',id);
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/testimonial/view/${id}`,)
        dispatch(testimonialEdit(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}


export const addTestimonial = (value) => async dispatch => {
    try {
        const res = await Api('post', `${REACT_APP_API_URL}/testimonial/add`,value)
        dispatch(createTestimonial(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const updateTestimonial = (value) => async dispatch => {
    try {
        const res = await Api('put', `${REACT_APP_API_URL}/testimonial/update`,value)
        dispatch(createTestimonial(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const deleteTestimonial = (id) => async dispatch => {
    try {
        const res = await Api('delete', `${REACT_APP_API_URL}/testimonial/delete/${id}`)
        dispatch(testimonialDelete(res.data.message))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}