import { createSlice } from '@reduxjs/toolkit'
import 'dotenv'
import { Api } from '../httpServices'
const { REACT_APP_API_URL } = process.env

export const cmsSlice = createSlice({
    name: 'cms',
    initialState:{
        cmses : [],
        cmslist : [],
        errorApiData: '',
        errorMessage: '',
        successMsg: '',
        cmsById : {},
        cmsUpd : {},
        cmsData : {},
        deleteSuccess : false,
        statusSuccess : false
    },
    reducers: {
        apiFailed: (state, action) => {
            state.errorApiData = action.payload;
        },
        allCmses : (state, action) => {
            state.cmses = action.payload;
        },
        CmsDetailData : (state, action) => {
            state.cmsData = action.payload;
        },
        cmsDelete : (state) => {
            state.deleteSuccess = true;
        },
        statusCms: (state) => {
            state.statusSuccess = true;
        },
        cmsUpdate : (state, action) => {
            state.successMsg = action.payload;
        },
        resetState : (state) => {
            state.successMsg = "";
            state.deleteSuccess = false;
            state.errorApiData = "";
            state.statusSuccess = false;
            state.createSuccessMsg = "";
            state.cmsById = "";
        },
    },
});

// Action creators are generated for each case reducer function
export const {resetState, apiFailed,allCmses,CmsDetailData,cmsUpdate } = cmsSlice.actions
export default cmsSlice.reducer

export const getCmses = () => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/cms-list`)
        dispatch(allCmses(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}


export const editCms = (_id) => async dispatch => {
    try {
        const res = await Api('get', `${REACT_APP_API_URL}/cms-edit/${_id}`)
        dispatch(CmsDetailData(res.data.data))
    } catch (e) {
        if (e.response) {
            dispatch(apiFailed(e.response.data))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}

export const updateCms = (value) => async dispatch => {
    try{
        const res = await Api('put', `${REACT_APP_API_URL}/update-cms`, value)
        dispatch(cmsUpdate(res.data.message))
    }catch(e){
        if (e.response) {
            dispatch(apiFailed(e.response.data.message))
        } else {
            dispatch(apiFailed(e.message))
        }
    }
}
